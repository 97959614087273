//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import openModalMixin from '~/views/UserPersonalFees/openModalMixin';
import UserCreatedModal from '~/views/UserCreatedModal/Modal.vue';
import UserCreateModalMixin from '~/views/UserCreatedModal/openModalMixin';
import constant from '~/const';

export default {

  mixins: [openModalMixin, UserCreateModalMixin],

  props: {
    data: { type: Object, default: () => ({}) },
    errors: { type: Object, default: () => ({}) },
    request: { type: Boolean, default: false },
  },
  data() {
    return {
      constant,
      newGroup: {
        name: '',
        loader: false,
      },
      createUserGroups: [1],
    };
  },

  components: {
    UserCreatedModal,
  },

  computed: {
    ...mapState('common', ['generalLoader', 'generalProgress', 'languages']),
    ...mapState('usersGroups', ['groups']),
    ...mapGetters('common', ['itemsPerPage']),

    showMerchantFees() {
      return this.$props.data.model?.roles?.includes(this.constant.users.USER_ROLE.MERCHANT);
    },

    showTraderFees() {
      return this.$props.data.model?.roles?.includes(this.constant.users.USER_ROLE.TRADER);
    },

    userIsMerchantOrTrader() {
      const rolesForGroup = [constant.users.USER_ROLE.MERCHANT, constant.users.USER_ROLE.TRADER];
      return this.$props.data.model.roles && this.$props.data.model.roles.some((role) => rolesForGroup.includes(role));
    },

    isSingleLocale() {
      return this.languages.length === 1;
    },

    getLanguageList() {
      return this.constant.languages.LANGUAGES_LIST.filter((lang) => { return this.languages.includes(Object.keys(this.constant.languages.LANGUAGES_CODES).find((key) => { return this.constant.languages.LANGUAGES_CODES[key] === lang.id; })); });
    },

    languagePlaceholder() {
      return this.languages.length === 1 ? this.$t(`const.${this.constant.languages.LANGUAGES_LIST[this.constant.languages.LANGUAGES_CODES[this.languages[0]]].caption}`) : ' ';
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('users', {
      getUsersAction: 'getUsers',
      createUserAction: 'createUser',
    }),
    ...mapActions('tradersFees', {
      editPersonalFees: 'editPersonalFees',
      loadPersonalFees: 'loadPersonalFeeDataById',
    }),
    ...mapActions('usersGroups', {
      loadUserGroups: 'getGroups',
      addUsersToGroup: 'addUsersToGroup',
      removeUsersFromGroup: 'removeUsersFromGroup',
      statsProfileById: 'statsProfileById',
      addGroup: 'addGroup',
    }),
    ...mapActions('tradersCurrencies', {
      loadTradersCurrenciesLazy: 'loadDataLazy',
    }),

    async createUser() {
      this.validateEmail();
      this.validateUserName();
      this.validatePassword();
      this.validateRoles();
      if (Object.values(this.$props.errors.createUserModal).some((e) => e)) {
        return;
      }

      this.setGeneralProgress(true);
      this.$props.data.loading = true;
      try {
        const createResult = await this.createUserAction({
          ...this.$props.data.model,
          userName: this.$props.data.model.userName.toLowerCase(),
          password: this.$props.data.model.password,
        });
        createResult.roles = this.$props.data.model.roles;
        if (
          this.$props.data.model.roles.includes(constant.users.USER_ROLE.MERCHANT)
          || this.$props.data.model.roles.includes(constant.users.USER_ROLE.TRADER)
        ) {
          if (this.createUserGroups.length) {
            await this.statsProfileById({ userId: createResult.userId });
            await Promise.allSettled(this.createUserGroups.map((groupId) => {
              return this.addUsersToGroup({
                userId: [String(createResult.userId)],
                id: groupId,
              });
            }));
            this.loadUserGroups();
            if (!this.createUserGroups.includes(1)) {
              this.removeUsersFromGroup({
                userId: [String(createResult.userId)],
                id: 1,
              });
            }
            this.setGroups([]);
          }
          await Promise.allSettled([
            this.loadPersonalFees({ userId: createResult.userId }),
            this.loadTradersCurrenciesLazy(),
          ]);
          this.openCreatedUserModalFees([],
            createResult.userId,
            createResult.userName,
            this.$props.data.model.email,
            this.$props.data.model.password);
        }
        this.$props.data.loading = false;
        this.setGeneralProgress(false);
        this.$props.data.show = false;
        this.$emit('created');
      } catch (error) {
        this.setErrorNotification(error.message);
        this.$props.data.loading = false;
        this.setGeneralProgress(false);
      }
    },

    setGroups(groupList) {
      this.createUserGroups = groupList;
    },

    closeUserCreateModal() {
      this.closeModal();
    },

    async addNewGroup() {
      this.newGroup.loader = true;
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to create group?', { groupName: this.newGroup.name }),
        callback: async () => {
          this.loader = true;
          try {
            const { name } = this.newGroup;
            const { id } = await this.addGroup({ Name: name });
            await this.loadUserGroups();
            this.setSuccessNotification('Group successfuly created');
            this.newGroup.name = '';
            this.createUserGroups.push(id);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
      this.newGroup.loader = false;
    },

    itemDisabledList(role) {
      if (
        (this.$props.data.model.roles?.includes?.(constant.users.USER_ROLE.TRADER) && role.id === constant.users.USER_ROLE.MERCHANT)
        || (this.$props.data.model.roles?.includes?.(constant.users.USER_ROLE.MERCHANT) && role.id === constant.users.USER_ROLE.TRADER)) {
        return 'disabled';
      }

      return null;
    },

    validateRoles(forced = true) {
      if (!forced && this.errors.createUserModal.roles === undefined) return;
      if (this.$props.data.model.roles.length <= 0) {
        this.errors.createUserModal.roles = this.$t('Must be filled');
        return;
      }
      this.errors.createUserModal.roles = '';
    },

    validateUserName(forced = true) {
      if (!forced && this.errors.createUserModal.userName === undefined) return;
      if (!this.$props.data.model.userName) {
        this.errors.createUserModal.userName = this.$t('Must be filled');
        return;
      }
      if (!this.constant.users.USERNAME_REGEXP.test(this.$props.data.model.userName)) {
        this.errors.createUserModal.userName = this.$t('The first character in the username must be a letter, length from 5 to 31 characters.');
        return;
      }
      this.errors.createUserModal.userName = '';
    },

    validatePassword(forced = true) {
      if (!forced && this.errors.createUserModal.password === undefined) return;
      if (!this.$props.data.model.password) {
        this.errors.createUserModal.password = this.$t('Must be filled');
        return;
      }
      if (!this.constant.users.PASSWORD_REGEXP_SIGNUP.test(this.$props.data.model.password)) {
        this.errors.createUserModal.password = this.$t('The password must contain from 8 to 64 characters: Latin lowercase and uppercase letters and numbers');
        return;
      }
      this.errors.createUserModal.password = '';
    },

    validateEmail(forced = true) {
      if (!forced && this.errors.createUserModal.email === undefined) return;
      if (!this.$props.data.model.email) {
        this.errors.createUserModal.email = this.$t('Must be filled');
        return;
      }
      if (!this.constant.users.EMAIL_REGEXP.test(this.$props.data.model.email)) {
        this.errors.createUserModal.email = this.$t('Enter a valid email, for example example@example.com.');
        return;
      }
      this.errors.createUserModal.email = '';
    },
  },
};
